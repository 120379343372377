import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import Layout from '../templates/layout'
import SEO from '../components/seo'

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/', { replace: true })
  })
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
    </Layout>
  )
}

export default NotFoundPage
